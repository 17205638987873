<template>
  <DefaultLayout background-image="green">
    <div class="flex flex-col items-center h-full">
      <div class="flex-1 flex flex-col justify-center">
        <ShotAnimationImage class="animation"/>
      </div>
    </div>
  </DefaultLayout>
</template>

<script setup lang="ts">
import DefaultLayout from "../layouts/DefaultLayout.vue";
import ShotAnimationImage from "./ShotAnimationImage.vue";
import { useGlobalStore } from "../stores/global";
import { onMounted } from "vue";

const globalStore = useGlobalStore();

const moveToGame = () => {
  setTimeout(() => {
    globalStore.changeStep("peopleCheck");
  }, 2900);
};

onMounted(() => {
  moveToGame();
});
</script>
<style lang="scss" scoped>
.animation {
  transform: scale(1.2);
}


</style>
