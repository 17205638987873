import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center h-full" }
const _hoisted_2 = { class: "flex-1 flex flex-col justify-center" }

import DefaultLayout from "../layouts/DefaultLayout.vue";
import ShotAnimationImage from "./ShotAnimationImage.vue";
import { useGlobalStore } from "../stores/global";
import { onMounted } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ShotAnimation',
  setup(__props) {

const globalStore = useGlobalStore();

const moveToGame = () => {
  setTimeout(() => {
    globalStore.changeStep("peopleCheck");
  }, 2900);
};

onMounted(() => {
  moveToGame();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DefaultLayout, { "background-image": "green" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(ShotAnimationImage, { class: "animation" })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})