<template>
  <DefaultLayout>
    <div class="flex flex-col items-center h-full">
      <div class="flex-1 flex flex-col justify-center">
        <div class="">
          <AgeSelector />
        </div>
      </div>
      <div class="relative flex flex-col justify-center items-center">
        <div>
          <img
            :src="drinkReponsible"
            alt="drink responsible"
            class="w-[160px] mb-6"
          />
        </div>
        <div
          class="text-sm uppercase cursor-pointer opacity-50 text-jm_white mb-4 text-center"
          @click="globalStore.changeStep('termsAndConditions')"
        >
          {{ $t("age_check.legal_conditions") }}
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script lang="ts" setup>
import DefaultLayout from "../layouts/DefaultLayout.vue";
import AgeSelector from "@/components/AgeSelector.vue";

import drinkReponsible from "@/assets/images/misc/drink-responsible.svg";
import { useGlobalStore } from "../stores/global";

const globalStore = useGlobalStore();
</script>

<style lang="scss" scoped>
@import "@/assets/sass/main.sass";
</style>
