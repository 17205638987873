import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container py-4" }
const _hoisted_2 = { class: "border-8 rounded-3xl border-jm_orange bg-jm_dark_green max-w-[90%] mx-auto py-6 px-4" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "text-lg font-bold uppercase text-center leading-tight max-w-md text-jm_white mx-auto" }
const _hoisted_5 = { class: "mt-4 text-lg sm:text-sm text-center leading-tight max-w-md text-jm_white mx-auto whitespace-pre-line" }
const _hoisted_6 = { class: "flex sm:flex-row items-center justify-center mt-8 sm:mt-10 gap-3" }

import { useGlobalStore } from "../stores/global";
import logo from "@/assets/images/misc/logo_jaegermeister_white.svg";


export default /*@__PURE__*/_defineComponent({
  __name: 'AgeSelector',
  setup(__props) {

const globalStore = useGlobalStore();

const handleClick = (answer: string) => {
  if (answer === "yes") {
    globalStore.playBackgroundMusic();
    globalStore.confirmAgeCheck(true);
    globalStore.changeStep("shotAnimation");
  } else {
    globalStore.changeStep("ageCheckFailed");
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _unref(logo),
        alt: "logo",
        class: "w-[80px] sm:w-[100px] mb-6 slide mx-auto"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("age_check.message")), 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("age_check.enter_birthday")), 1),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (handleClick('yes'))),
          type: "button",
          class: "py-2.5 px-5 text-xs sm:text-sm font-medium bg-jm_white rounded-xl border border-gray-200 uppercase text-jm_dark_green w-28 sm:w-32"
        }, _toDisplayString(_ctx.$t("age_check.confirm_button")), 1),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (handleClick('no'))),
          type: "button",
          class: "py-2.5 px-5 text-xs sm:text-sm font-medium bg-jm_white rounded-xl border border-gray-200 uppercase text-jm_dark_green w-28 sm:w-32"
        }, _toDisplayString(_ctx.$t("age_check.reject_button")), 1)
      ])
    ])
  ]))
}
}

})