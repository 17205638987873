import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "text-lg leading-5 font-bold text-header" }
const _hoisted_2 = {
  key: 0,
  class: "text-lg leading-5 uppercase text-action"
}




export default /*@__PURE__*/_defineComponent({
  __name: 'ActionDescription',
  props: {
    currentHeader: {},
    currentSubheader: {},
    isStopped: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["text-center max-w-80 mx-auto text-jm_white", _ctx.isStopped ? 'mt-6 mb-4' : 'my-4'])
  }, [
    _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.currentHeader), 1),
    (_ctx.isStopped)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.currentSubheader), 1))
      : _createCommentVNode("", true)
  ], 2))
}
}

})