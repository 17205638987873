import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container mx-auto h-full flex flex-col justify-center items-center relative z-10" }

import { ref, onMounted, watch } from "vue";
import backgroundImagePeople from "@/assets/images/backgrounds/bg-agegate-md.jpg";
import backgroundImageGreen from "@/assets/images/backgrounds/bg-green-xl.jpg";
import { useGlobalStore } from "@/stores/global";


export default /*@__PURE__*/_defineComponent({
  __name: 'DefaultLayout',
  props: {
    backgroundImage: {}
  },
  setup(__props: any) {

const globalStore = useGlobalStore();

const background = ref<string>(backgroundImageGreen);
const previousBackground = ref<string>(backgroundImageGreen);
const currentOpacity = ref(1);
const previousOpacity = ref(0);

const props = __props;

onMounted(() => {
  setBackgroundImage();
});

watch(
  () => props.backgroundImage,
  () => {
    setBackgroundImage();
  }
);

const setBackgroundImage = () => {
  previousBackground.value = background.value;
  background.value =
    props.backgroundImage === "people" ? backgroundImagePeople : backgroundImageGreen;
  previousOpacity.value = 1;
  currentOpacity.value = 0;

  setTimeout(() => {
    previousOpacity.value = 0;
    currentOpacity.value = 1;
  }, 50);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "relative max-w-4xl mx-auto h-[100dvh] pt-4 pb-4",
    style: _normalizeStyle(_unref(globalStore).currentStep === 'termsAndConditions' ? '' : 'overflow: hidden')
  }, [
    _createElementVNode("div", {
      class: "bg-cover bg-center absolute inset-0 transition-opacity",
      style: _normalizeStyle({ backgroundImage: `url(${previousBackground.value})`, opacity: previousOpacity.value })
    }, null, 4),
    _createElementVNode("div", {
      class: "bg-cover bg-center absolute inset-0 transition-opacity",
      style: _normalizeStyle({ backgroundImage: `url(${background.value})`, opacity: currentOpacity.value })
    }, null, 4),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 4))
}
}

})