import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center" }
const _hoisted_2 = { class: "mr-4 back-button" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]

import reload from "@/assets/images/misc/reload.svg";
import EventBus from "@/plugins/EventBus";
import { ref, onMounted, onBeforeUnmount, watch } from "vue";
import textImage from "@/assets/images/misc/text.svg";
import CloseButton from "@/components/CloseButton.vue";
import { useSession } from "@/mixins/createSession";
import { useGlobalStore } from "@/stores/global";
import arrow from "@/assets/images/misc/arrow-left.svg";


export default /*@__PURE__*/_defineComponent({
  __name: 'PushButton',
  emits: ["isStopped"],
  setup(__props, { emit: __emit }) {

let campaignId = new URL(location.href).searchParams.get("campaignId") ?? "";

const globalStore = useGlobalStore();

const session = globalStore.session as string;

const emit = __emit;
const imageSelected = ref(false);
const clickCounter = ref(0);
let keyHeldDown = false;

const triggerMethod = () => {
  // Workaround to prevent the buzzer sound playing when moving back to the age check
  // Needs refactoring, because the buttons shouldn't be nested in the template
  if (imageSelected.value || globalStore.currentStep === "ageCheck") {
    globalStore.playTapSound();
  } else {
    globalStore.playBuzzerSound();
  }

  imageSelected.value = !imageSelected.value;
  EventBus.emit("startGame", imageSelected.value);
  emit("isStopped", imageSelected.value);

  clickCounter.value++;

  if (clickCounter.value === 2) {
    globalStore.incrementTimesPlayed();
    clickCounter.value = 0;
  }

  if (globalStore.timesLeftToPlay <= 0) {
    useSession().createUpdateSession(session, campaignId as string);
  }
};

const handleKeydown = (event: KeyboardEvent) => {
  if (!keyHeldDown && (event.key === "b" || event.key === "Enter")) {
    keyHeldDown = true;
    triggerMethod();
  }
};

const handleKeyup = (event: KeyboardEvent) => {
  keyHeldDown = false;
};

const handleButtonClick = () => {
  globalStore.changeStep("peopleCheck");
  globalStore.playTapSound();
  globalStore.resetGame();
};

watch(
  () => globalStore.timesLeftToPlay,
  (newValue) => {
    if (newValue <= 0) {
      useSession().getFingerprintAndInitializeSession(campaignId);
    }
  }
);

onMounted(() => {
  window.addEventListener("keydown", handleKeydown);
  window.addEventListener("keyup", handleKeyup);
});

onBeforeUnmount(() => {
  window.removeEventListener("keydown", handleKeydown);
  window.removeEventListener("keyup", handleKeyup);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_unref(globalStore).timesLeftToPlay > 0 && !imageSelected.value)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "w-[80px] h-[80px]",
            src: _unref(arrow),
            onClick: handleButtonClick
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["relative flex justify-center items-center", { 'scale-[60%]': imageSelected.value }])
    }, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "absolute rounded-full border-2 border-dotted border-jm_white opacity-40 w-40 h-40 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-spin-slow" }, null, -1)),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "absolute rounded-full border-2 border-dotted border-jm_white w-36 opacity-60 h-36 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-spin-slower" }, null, -1)),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "absolute rounded-full border-2 border-dotted border-jm_white opacity-32 w-32 h-32 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-spin-slowest" }, null, -1)),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (triggerMethod())),
        class: _normalizeClass(["relative z-10 text-white rounded-full border-4 border-jm_white flex items-center justify-center size-28 p-2 leading-5 font-bold uppercase", _unref(globalStore).timesLeftToPlay <= 0 ? '' : 'bg-jm_orange'])
      }, [
        (_unref(globalStore).timesLeftToPlay <= 0)
          ? (_openBlock(), _createBlock(CloseButton, { key: 0 }))
          : (imageSelected.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                _createElementVNode("img", {
                  src: _unref(reload),
                  class: "scale-[90%]"
                }, null, 8, _hoisted_5)
              ]))
            : (_openBlock(), _createElementBlock("img", {
                key: 2,
                class: "h-full pulse",
                src: _unref(textImage)
              }, null, 8, _hoisted_6))
      ], 2)
    ], 2)
  ]))
}
}

})