<template>
  <div class="font-regular" :style="{ backgroundImage: `url(${backgroundImageGreen})` }">
    <AgeCheck v-if="globalStore.currentStep === 'ageCheck'" />
    <ShotAnimation v-else-if="globalStore.currentStep === 'shotAnimation'" />
    <PeopleCheck v-else-if="globalStore.currentStep === 'peopleCheck'" />
    <AgeCheckFailed v-else-if="globalStore.currentStep === 'ageCheckFailed'" />
    <GameMove v-else-if="globalStore.currentStep === 'gameMove'" />
    <TermsAndConditions v-else-if="globalStore.currentStep === 'termsAndConditions'" />
  </div>
</template>

<script lang="ts" setup>
import { useGlobalStore } from "./stores/global";
import AgeCheck from "./pages/ageCheck.vue";
import PeopleCheck from "./pages/peopleCheck.vue";
import { onMounted, watch } from "vue";
import AgeCheckFailed from "./pages/ageCheckFailed.vue";
import GameMove from "./pages/gameMove.vue";
import TermsAndConditions from "./pages/termsAndConditions.vue";
import i18n from "@/plugins/i18n";
import ShotAnimation from "./components/ShotAnimation.vue";
import backgroundImageGreen from "@/assets/images/backgrounds/bg-green-xl.jpg";

const globalStore = useGlobalStore();

const preventTouchMove = (e: TouchEvent) => {
  if (e.cancelable) {
    e.preventDefault();
  }
};
const preventTouchMoveOptions = { passive: false };

const removeScroll = () => {
  document.addEventListener("touchmove", preventTouchMove, preventTouchMoveOptions);
  console.log("scroll removed");
};

const addScroll = () => {
  document.removeEventListener("touchmove", preventTouchMove, preventTouchMoveOptions);
  console.log("scroll added");
};

watch(
  () => globalStore.currentStep,
  (newStep) => {
    if (newStep === "termsAndConditions") {
      addScroll();
    } else {
      removeScroll();
    }
  },
  { immediate: true }
);

onMounted(() => {
  let lang = new URL(location.href).searchParams.get("lang");
  if (lang) {
    i18n.global.locale = lang;
  }
  globalStore.changeStep("ageCheck");
  if (globalStore.currentStep === "termsAndConditions") {
    addScroll();
  } else {
    removeScroll();
  }
});
</script>

<style lang="scss" scoped>
@import "@/assets/sass/main.sass";
</style>
