import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center h-full" }
const _hoisted_2 = { class: "flex-1 flex flex-col justify-center" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "relative flex flex-col justify-center items-center" }
const _hoisted_5 = ["src"]

import DefaultLayout from "../layouts/DefaultLayout.vue";
import AgeSelector from "@/components/AgeSelector.vue";

import drinkReponsible from "@/assets/images/misc/drink-responsible.svg";
import { useGlobalStore } from "../stores/global";


export default /*@__PURE__*/_defineComponent({
  __name: 'ageCheck',
  setup(__props) {

const globalStore = useGlobalStore();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DefaultLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(AgeSelector)
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("img", {
              src: _unref(drinkReponsible),
              alt: "drink responsible",
              class: "w-[160px] mb-6"
            }, null, 8, _hoisted_5)
          ]),
          _createElementVNode("div", {
            class: "text-sm uppercase cursor-pointer opacity-50 text-jm_white mb-4 text-center",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(globalStore).changeStep('termsAndConditions')))
          }, _toDisplayString(_ctx.$t("age_check.legal_conditions")), 1)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})