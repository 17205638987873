<template>
  <div class="flex justify-center items-center">
    <div class="mr-4 back-button">
      <img
        class="w-[80px] h-[80px]"
        :src="arrow"
        @click="handleButtonClick"
        v-if="globalStore.timesLeftToPlay > 0 && !imageSelected"
      />
    </div>
    <div
      class="relative flex justify-center items-center"
      :class="{ 'scale-[60%]': imageSelected }"
    >
      <div
        class="absolute rounded-full border-2 border-dotted border-jm_white opacity-40 w-40 h-40 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-spin-slow"
      ></div>
      <div
        class="absolute rounded-full border-2 border-dotted border-jm_white w-36 opacity-60 h-36 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-spin-slower"
      ></div>
      <div
        class="absolute rounded-full border-2 border-dotted border-jm_white opacity-32 w-32 h-32 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-spin-slowest"
      ></div>
      <button
        @click="triggerMethod()"
        class="relative z-10 text-white rounded-full border-4 border-jm_white flex items-center justify-center size-28 p-2 leading-5 font-bold uppercase"
        :class="globalStore.timesLeftToPlay <= 0 ? '' : 'bg-jm_orange'"
      >
        <CloseButton v-if="globalStore.timesLeftToPlay <= 0" />
        <span v-else-if="imageSelected"
          ><img
            :src="reload"
            class="scale-[90%]"
          />
        </span>
        <img
          class="h-full pulse"
          v-else
          :src="textImage"
        />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import reload from "@/assets/images/misc/reload.svg";
import EventBus from "@/plugins/EventBus";
import { ref, onMounted, onBeforeUnmount, defineEmits, watch } from "vue";
import textImage from "@/assets/images/misc/text.svg";
import CloseButton from "@/components/CloseButton.vue";
import { useSession } from "@/mixins/createSession";
import { useGlobalStore } from "@/stores/global";
import arrow from "@/assets/images/misc/arrow-left.svg";

let campaignId = new URL(location.href).searchParams.get("campaignId") ?? "";

const globalStore = useGlobalStore();

const session = globalStore.session as string;

const emit = defineEmits(["isStopped"]);
const imageSelected = ref(false);
const clickCounter = ref(0);
let keyHeldDown = false;

const triggerMethod = () => {
  // Workaround to prevent the buzzer sound playing when moving back to the age check
  // Needs refactoring, because the buttons shouldn't be nested in the template
  if (imageSelected.value || globalStore.currentStep === "ageCheck") {
    globalStore.playTapSound();
  } else {
    globalStore.playBuzzerSound();
  }

  imageSelected.value = !imageSelected.value;
  EventBus.emit("startGame", imageSelected.value);
  emit("isStopped", imageSelected.value);

  clickCounter.value++;

  if (clickCounter.value === 2) {
    globalStore.incrementTimesPlayed();
    clickCounter.value = 0;
  }

  if (globalStore.timesLeftToPlay <= 0) {
    useSession().createUpdateSession(session, campaignId as string);
  }
};

const handleKeydown = (event: KeyboardEvent) => {
  if (!keyHeldDown && (event.key === "b" || event.key === "Enter")) {
    keyHeldDown = true;
    triggerMethod();
  }
};

const handleKeyup = (event: KeyboardEvent) => {
  keyHeldDown = false;
};

const handleButtonClick = () => {
  globalStore.changeStep("peopleCheck");
  globalStore.playTapSound();
  globalStore.resetGame();
};

watch(
  () => globalStore.timesLeftToPlay,
  (newValue) => {
    if (newValue <= 0) {
      useSession().getFingerprintAndInitializeSession(campaignId);
    }
  }
);

onMounted(() => {
  window.addEventListener("keydown", handleKeydown);
  window.addEventListener("keyup", handleKeyup);
});

onBeforeUnmount(() => {
  window.removeEventListener("keydown", handleKeydown);
  window.removeEventListener("keyup", handleKeyup);
});
</script>

<style lang="scss" scoped>
@import "@/assets/sass/main.sass";

@keyframes spin-slow {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes spin-slower {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin-slowest {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.animate-spin-slow {
  animation: spin-slow 26s linear infinite;
}

.animate-spin-slower {
  animation: spin-slower 28s linear infinite;
}

.animate-spin-slowest {
  animation: spin-slowest 30s linear infinite;
}

.pulse {
  animation: pulse-animation 2s infinite;
  border-radius: 50%;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    transform: scale(1.1);
  }
}

.back-button {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: 28px;
  cursor: pointer;
}
</style>
