import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-regular text-jm_white leading-none text-center mb-4" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "font-bold leading-none text-[6rem] text-jm_white text-center" }
const _hoisted_4 = { class: "font-bold text-4xl leading-none text-jm_white my-4 text-center" }
const _hoisted_5 = { class: "px-6 text-lg leading-tight text-jm_white mb-4 text-center" }

import CloseButton from "@/components/CloseButton.vue";
import DefaultLayout from "../layouts/DefaultLayout.vue";
import logo from "@/assets/images/misc/logo.svg";

export default /*@__PURE__*/_defineComponent({
  __name: 'ageCheckFailed',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DefaultLayout, { "background-image": "people" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: _unref(logo),
          alt: "logo",
          class: "w-[200px]"
        }, null, 8, _hoisted_2)
      ]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("age_check_failed.failed_header")), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("age_check_failed.failed_subheader")), 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("age_check_failed.failed_content")), 1),
      _createVNode(CloseButton)
    ]),
    _: 1
  }))
}
}

})