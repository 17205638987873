import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import close from "@/assets/images/misc/close.svg";
import { useGlobalStore } from "../stores/global";


export default /*@__PURE__*/_defineComponent({
  __name: 'CloseButton',
  setup(__props) {

const globalStore = useGlobalStore();

const handleButtonClick = () => {
  globalStore.changeStep("ageCheck");
  globalStore.playTapSound();
  globalStore.resetGame();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("button", {
      type: "button",
      class: "rounded-full items-center bg-jm_dark_green py-2 px-2",
      onClick: handleButtonClick
    }, [
      _createElementVNode("img", {
        src: _unref(close),
        alt: "close",
        class: "rounded-full"
      }, null, 8, _hoisted_1)
    ])
  ]))
}
}

})