<template>
  <DefaultLayout background-image="people">
    <div class="flex flex-col items-center h-full relative px-4">
      <div class="flex-1 flex flex-col items-center justify-center">
        <img
          :src="logo"
          alt="logo"
          class="logo-jm w-32 md:w-40 lg:w-48 mb-3 mx-auto"
          :class="{ 'slide-from-top': animateLogo }"
        />
        <img
          :src="shotMachine"
          alt="shot machine"
          class="mb-3 mx-auto shot-machine"
          :class="{ 'slide-from-bottom': animateShotMachine }"
        />
      </div>

      <div class="relative flex flex-col justify-center items-center mb-4">
        <PeopleSelector class="text-center mb-3" />
        <PlayButton class="mb-6" />
      </div>

      <div class="relative flex flex-col justify-center items-center">
        <img
          :src="drinkReponsible"
          alt="drink responsible"
          class="w-40 md:w-48 lg:w-56 mb-6"
        />
      </div>

      <div
        class="text-sm uppercase cursor-pointer opacity-50 text-jm_white mb-4 text-center"
        @click="globalStore.changeStep('termsAndConditions')"
      >
        {{ $t("age_check.legal_conditions") }}
      </div>
    </div>
  </DefaultLayout>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import PlayButton from "@/components/PlayButton.vue";
import DefaultLayout from "../layouts/DefaultLayout.vue";
import PeopleSelector from "@/components/PeopleSelector.vue";
import logo from "@/assets/images/misc/logo.svg";
import shotMachine from "@/assets/images/misc/shot_machine_static.svg";
import drinkReponsible from "@/assets/images/misc/drink-responsible.svg";
import { useGlobalStore } from "../stores/global";

const globalStore = useGlobalStore();

const animateLogo = ref(false);
const animateShotMachine = ref(false);

onMounted(() => {
  setTimeout(() => {
    animateLogo.value = true;
  }, 200);

  setTimeout(() => {
    animateShotMachine.value = true;
  }, 300);
});
</script>

<style lang="scss" scoped>
@import "@/assets/sass/main.sass";

.logo-jm {
  opacity: 0; /* Start hidden */
  transform: translateY(-100px);
  transition: opacity 1s ease, transform 1s ease;
}

.slide-from-top {
  opacity: 1 !important; 
  transform: translateY(0) !important;
}

.shot-machine {
  transform: translateY(110px);
  transition: transform 1s ease, opacity 1s ease;
}

.slide-from-bottom {
  opacity: 1 !important;
  transform: translateY(0) !important;
}
</style>
