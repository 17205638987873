import { createI18n } from "vue-i18n";

const messages = {
  en: {
    age_check: {
      // Age Check
      header: "SHOT MACHINE",
      message: "Are you 18 or older?",
      day: "Day",
      month: "Month",
      year: "Year",
      enter_birthday: "We attach great importance to the responsible use of alcohol. \n You must therefore be of legal age to visit this site.",
      day_placeholder: "DD",
      month_placeholder: "MM",
      year_placeholder: "YYYY",
      how_many_people: "How many people will be playing?",
      person: "person",
      people: "people",
      confirm_button: "yes",
      play_button: "Confirm",
      reject_button: "no",
      legal_conditions: "Privacy policy + Legal conditions",
      // age check erros
      error_day_message: "Please enter a valid day",
      error_month_message: "Please enter a valid month",
      error_year_message: "Please enter a valid year",
    },
    age_check_failed: {
      failed_header: "ARGH",
      failed_subheader: "THAT'S A SHAME",
      failed_content: "We take the youth protection act very seriously. Unfortunately, you cannot take part in the Shot Machine.",
      legal_conditions: "Privacy policy + Legal conditions",
    },
    image_slider: {
      thats_enough: "That's enough for today.",
      thanks_for_playing: "Thanks for playing!",
      loading: "Loading...",
      please_wait: "Please wait...",
    },
  },
  de: {
    age_check: {
      // Age Check
      header: "SHOT MACHINE",
      message: "Bist du 18 Jahre oder älter?",
      day: "Tag",
      month: "Monat",
      year: "Jahr",
      enter_birthday: "Wir legen großen Wert auf den verantwortungsbewussten Umgang mit Alkohol. \n Du musst daher volljährig sein, um diese Seite besuchen zu dürfen.",
      day_placeholder: "TT",
      month_placeholder: "MM",
      year_placeholder: "JJJJ",
      how_many_people: "Wie viele Personen spielen mit?",
      person: "Person",
      people: "Personen",
      confirm_button: "ja",
      play_button: "Bestätigen",
      reject_button: "nein",
      legal_conditions: "Datenschutz + Rechtliche Hinweise",
      // age check erros
      error_day_message: "Bitte gib einen gültigen Tag ein",
      error_month_message: "Bitte gib einen gültigen Monat ein",
      error_year_message: "Bitte gib ein gültiges Jahr ein",
    },
    age_check_failed: {
      failed_header: "ARGH",
      failed_subheader: "DAS IST SCHADE",
      failed_content: "Wir nehmen das Jugendschutzgesetz sehr ernst. Leider kannst du nicht am Buzzer-Spiel teilnehmen.",
      legal_conditions: "Datenschutz + Rechtliche Hinweise",
    },
    image_slider: {
      thats_enough: "Das reicht für heute.",
      thanks_for_playing: "Danke für's Spielen!",
      loading: "Laden...",
      please_wait: "Bitte warten...",
    },
  },
};
const i18n = createI18n({
  locale: "en",
  legacy: true,
  fallbackLocale: "en",
  messages,
});

export default i18n;
