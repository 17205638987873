import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-bold text-xl leading-tight text-jm_white px-2 text-center uppercase min-w-96" }
const _hoisted_2 = { class: "flex justify-center w-full" }
const _hoisted_3 = { class: "person-picker bg-jm_green rounded-[10px]" }

import { ref, onMounted, watch } from "vue";
import { useGlobalStore } from "../stores/global";


export default /*@__PURE__*/_defineComponent({
  __name: 'PeopleSelector',
  setup(__props) {

const globalStore = useGlobalStore();
const selectedPerson = ref<string>("1");
const animate = ref(false);

const updatePeopleCount = () => {
  const personCount = Math.min(parseInt(selectedPerson.value), 99);
  if (!isNaN(personCount) && personCount > 0) {
    globalStore.setSelectedPerson(personCount);
    selectedPerson.value = personCount.toString();
  } else {
    selectedPerson.value = "";
  }
};

const validateInput = (event: KeyboardEvent) => {
  const char = String.fromCharCode(event.keyCode);
  if (!/[0-9]/.test(char)) {
    event.preventDefault();
  }
};

watch(selectedPerson, () => {
  if (selectedPerson.value === "") {
    globalStore.setSelectedPerson(0);
  }
});

onMounted(() => {
  setTimeout(() => {
    animate.value = true;
  }, 400);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container py-2 font-bold flex-col align-center uppercase min-w-96 people-selector", { 'animate-people-selector': animate.value }])
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("age_check.how_many_people")), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "people-input font-extrabold",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedPerson).value = $event)),
          onInput: updatePeopleCount,
          onKeypress: validateInput
        }, null, 544), [
          [_vModelText, selectedPerson.value]
        ])
      ])
    ])
  ], 2))
}
}

})