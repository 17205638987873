<template>
  <div class="container py-4">
    <div class="border-8 rounded-3xl border-jm_orange bg-jm_dark_green max-w-[90%] mx-auto py-6 px-4">
      <img
        :src="logo"
        alt="logo"
        class="w-[80px] sm:w-[100px] mb-6 slide mx-auto"
      />
      <div class="text-lg font-bold uppercase text-center leading-tight max-w-md text-jm_white mx-auto">
        {{ $t("age_check.message") }}
      </div>
      <div class="mt-4 text-lg sm:text-sm text-center leading-tight max-w-md text-jm_white mx-auto whitespace-pre-line">
        {{ $t("age_check.enter_birthday") }}
      </div>
      <div class="flex sm:flex-row items-center justify-center mt-8 sm:mt-10 gap-3">
        <button
          @click="handleClick('yes')"
          type="button"
          class="py-2.5 px-5 text-xs sm:text-sm font-medium bg-jm_white rounded-xl border border-gray-200 uppercase text-jm_dark_green w-28 sm:w-32"
        >
          {{ $t("age_check.confirm_button") }}
        </button>
        <button
          @click="handleClick('no')"
          type="button"
          class="py-2.5 px-5 text-xs sm:text-sm font-medium bg-jm_white rounded-xl border border-gray-200 uppercase text-jm_dark_green w-28 sm:w-32"
        >
          {{ $t("age_check.reject_button") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGlobalStore } from "../stores/global";
import logo from "@/assets/images/misc/logo_jaegermeister_white.svg";

const globalStore = useGlobalStore();

const handleClick = (answer: string) => {
  if (answer === "yes") {
    globalStore.playBackgroundMusic();
    globalStore.confirmAgeCheck(true);
    globalStore.changeStep("shotAnimation");
  } else {
    globalStore.changeStep("ageCheckFailed");
  }
};
</script>

<style lang="sass" scoped>
@import "@/assets/sass/main.sass"
</style>
