import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex h-full flex-col items-center justify-between mb-10" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "flex flex-col items-center" }
const _hoisted_4 = { class: "tall:mb-12" }

import PushButton from "@/components/PushButton.vue";
import DefaultLayout from "../layouts/DefaultLayout.vue";
import ImageSlider from "@/components/ImageSlider.vue";
import logo from "@/assets/images/misc/logo.svg";
import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'gameMove',
  setup(__props) {

const showingLogo = ref(true);

const showLogo = (isStopped: boolean) => {
  showingLogo.value = !isStopped;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DefaultLayout, { "background-image": "green" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("img", {
            src: _unref(logo),
            alt: "logo",
            class: _normalizeClass(["h-16 tall:h-32 tall:mt-12 m-4", showingLogo.value ? '' : 'opacity-0'])
          }, null, 10, _hoisted_2)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(ImageSlider)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(PushButton, { onIsStopped: showLogo })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})