import { defineStore } from "pinia";
import soundMusic from "@/assets/sounds/music.mp3";
import soundBuzzer from "@/assets/sounds/buzzer.mp3";
import soundTap from "@/assets/sounds/tap.mp3";

export const useGlobalStore = defineStore({
  id: "global",
  state: () => ({
    currentStep: "peopleCheck",
    ageCheckConfirmed: false,
    timesLeftToPlay: 2,
    timesPlayed: 0,
    selectedPerson: 1,
    totalPlays: 0,
    session: null as null | unknown,
    country: null as null | unknown,
    sounds: {
      backgroundMusic: new Audio(soundMusic),
      buzzerSound: new Audio(soundBuzzer),
      tapSound: new Audio(soundTap),
    },
  }),
  actions: {
    changeStep(step: string) {
      this.currentStep = step;
    },
    confirmAgeCheck(value: boolean) {
      this.ageCheckConfirmed = value;
    },
    setTimesLeftToPlay(times: number) {
      this.timesLeftToPlay = times;
    },
    incrementTimesPlayed() {
      if (this.timesLeftToPlay > 0) {
        this.timesPlayed++;
        this.timesLeftToPlay--;
        this.totalPlays++;
      }
    },
    resetGame() {
      this.timesPlayed = 0;
      this.timesLeftToPlay = this.selectedPerson * 2;
    },
    setSelectedPerson(person: number) {
      this.selectedPerson = person;
      this.resetGame();
    },
    playBackgroundMusic() {
      this.sounds.backgroundMusic.loop = true;
      this.sounds.backgroundMusic.play();
    },
    pauseBackgroundMusic() {
      this.sounds.backgroundMusic.pause();
    },
    playBuzzerSound() {
      const buzzerSound = new Audio(soundBuzzer);
      buzzerSound.play();
    },
    playTapSound() {
      const tapSound = new Audio(soundTap);
      tapSound.play();
    },
    setSession(session: unknown) {
      this.session = session;
    },
    setCountry(country: unknown) {
      this.country = country;
    },
  },
});
