import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, onMounted } from 'vue';
import { useGlobalStore } from "../stores/global";


export default /*@__PURE__*/_defineComponent({
  __name: 'PlayButton',
  setup(__props) {

const globalStore = useGlobalStore();
const animate = ref(false);4

const handleButtonClick = () => {
  if (globalStore.selectedPerson === 0) {
    return;
  }
  globalStore.changeStep("gameMove");
};

onMounted(() => {
  setTimeout(() => {
    animate.value = true;
  }, 500);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'cursor-not-allowed': _unref(globalStore).selectedPerson === 0, 'opacity-50': _unref(globalStore).selectedPerson === 0 })
  }, [
    _createElementVNode("button", {
      class: _normalizeClass(["text-white py-2 px-6 uppercase text-center w-full text-xl font-bold rounded-lg bg-orange-500 play-button", { 'animate-play-button': animate.value }]),
      onClick: handleButtonClick
    }, _toDisplayString(_ctx.$t("age_check.play_button")), 3)
  ], 2))
}
}

})