<template>
  <div
    class="container py-2 font-bold flex-col align-center uppercase min-w-96 people-selector"
    :class="{ 'animate-people-selector': animate }"
  >
    <div class="font-bold text-xl leading-tight text-jm_white px-2 text-center uppercase min-w-96">
      {{ $t("age_check.how_many_people") }}
    </div>
    <div class="flex justify-center w-full">
      <div class="person-picker bg-jm_green rounded-[10px]">
        <input
          type="text"
          class="people-input font-extrabold"
          v-model="selectedPerson"
          @input="updatePeopleCount"
          @keypress="validateInput"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import { useGlobalStore } from "../stores/global";

const globalStore = useGlobalStore();
const selectedPerson = ref<string>("1");
const animate = ref(false);

const updatePeopleCount = () => {
  const personCount = Math.min(parseInt(selectedPerson.value), 99);
  if (!isNaN(personCount) && personCount > 0) {
    globalStore.setSelectedPerson(personCount);
    selectedPerson.value = personCount.toString();
  } else {
    selectedPerson.value = "";
  }
};

const validateInput = (event: KeyboardEvent) => {
  const char = String.fromCharCode(event.keyCode);
  if (!/[0-9]/.test(char)) {
    event.preventDefault();
  }
};

watch(selectedPerson, () => {
  if (selectedPerson.value === "") {
    globalStore.setSelectedPerson(0);
  }
});

onMounted(() => {
  setTimeout(() => {
    animate.value = true;
  }, 400);
});
</script>

<style lang="scss" scoped>
@import "@/assets/sass/main.sass";

.container {
  z-index: 1;
  width: fit-content;
  opacity: 0;
  transform: translateY(500px);
  transition: transform 1s ease, opacity 1s ease;
}

.animate-people-selector {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.person-picker {
  display: flex;
  margin-top: 1rem;
  justify-content: center;
  padding: 0.75rem;
  width: fit-content;
}

.people-input {
  padding-left: 20px;
  padding: 0.5rem;
  border: 1px solid $jm_white;
  border-radius: 10px;
  font-size: 1rem;
  width: 100px;
  text-align: center;
  background-color: $jm_green;
  color: $jm_white;
  max-width: 140px;
  color: $jm_orange;
}
</style>
