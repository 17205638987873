<template>
  <div :class="{'cursor-not-allowed': globalStore.selectedPerson === 0, 'opacity-50': globalStore.selectedPerson === 0 }">
    <button
      class="text-white py-2 px-6 uppercase text-center w-full text-xl font-bold rounded-lg bg-orange-500 play-button"
      @click="handleButtonClick"
      :class="{ 'animate-play-button': animate }"
    >
      {{ $t("age_check.play_button") }}
    </button>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useGlobalStore } from "../stores/global";

const globalStore = useGlobalStore();
const animate = ref(false);4

const handleButtonClick = () => {
  if (globalStore.selectedPerson === 0) {
    return;
  }
  globalStore.changeStep("gameMove");
};

onMounted(() => {
  setTimeout(() => {
    animate.value = true;
  }, 500);
});
</script>

<style lang="scss" scoped>
@import "@/assets/sass/main.sass";

.play-button {
  opacity: 0;
  transform: translateY(500px);
  transition: transform 1s ease, opacity 1s ease;
}

.animate-play-button {
  opacity: 1 !important;
  transform: translateY(0) !important;
}
</style>
