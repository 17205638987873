import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

import { useGlobalStore } from "./stores/global";
import AgeCheck from "./pages/ageCheck.vue";
import PeopleCheck from "./pages/peopleCheck.vue";
import { onMounted, watch } from "vue";
import AgeCheckFailed from "./pages/ageCheckFailed.vue";
import GameMove from "./pages/gameMove.vue";
import TermsAndConditions from "./pages/termsAndConditions.vue";
import i18n from "@/plugins/i18n";
import ShotAnimation from "./components/ShotAnimation.vue";
import backgroundImageGreen from "@/assets/images/backgrounds/bg-green-xl.jpg";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const globalStore = useGlobalStore();

const preventTouchMove = (e: TouchEvent) => {
  if (e.cancelable) {
    e.preventDefault();
  }
};
const preventTouchMoveOptions = { passive: false };

const removeScroll = () => {
  document.addEventListener("touchmove", preventTouchMove, preventTouchMoveOptions);
  console.log("scroll removed");
};

const addScroll = () => {
  document.removeEventListener("touchmove", preventTouchMove, preventTouchMoveOptions);
  console.log("scroll added");
};

watch(
  () => globalStore.currentStep,
  (newStep) => {
    if (newStep === "termsAndConditions") {
      addScroll();
    } else {
      removeScroll();
    }
  },
  { immediate: true }
);

onMounted(() => {
  let lang = new URL(location.href).searchParams.get("lang");
  if (lang) {
    i18n.global.locale = lang;
  }
  globalStore.changeStep("ageCheck");
  if (globalStore.currentStep === "termsAndConditions") {
    addScroll();
  } else {
    removeScroll();
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "font-regular",
    style: _normalizeStyle({ backgroundImage: `url(${_unref(backgroundImageGreen)})` })
  }, [
    (_unref(globalStore).currentStep === 'ageCheck')
      ? (_openBlock(), _createBlock(AgeCheck, { key: 0 }))
      : (_unref(globalStore).currentStep === 'shotAnimation')
        ? (_openBlock(), _createBlock(ShotAnimation, { key: 1 }))
        : (_unref(globalStore).currentStep === 'peopleCheck')
          ? (_openBlock(), _createBlock(PeopleCheck, { key: 2 }))
          : (_unref(globalStore).currentStep === 'ageCheckFailed')
            ? (_openBlock(), _createBlock(AgeCheckFailed, { key: 3 }))
            : (_unref(globalStore).currentStep === 'gameMove')
              ? (_openBlock(), _createBlock(GameMove, { key: 4 }))
              : (_unref(globalStore).currentStep === 'termsAndConditions')
                ? (_openBlock(), _createBlock(TermsAndConditions, { key: 5 }))
                : _createCommentVNode("", true)
  ], 4))
}
}

})