<template>
  <div class="relative max-w-4xl mx-auto h-[100dvh] pt-4 pb-4" :style="globalStore.currentStep === 'termsAndConditions' ? '' : 'overflow: hidden'">
    <div
      class="bg-cover bg-center absolute inset-0 transition-opacity"
      :style="{ backgroundImage: `url(${previousBackground})`, opacity: previousOpacity }"
    ></div>
    <div
      class="bg-cover bg-center absolute inset-0 transition-opacity"
      :style="{ backgroundImage: `url(${background})`, opacity: currentOpacity }"
    ></div>
    <div class="container mx-auto h-full flex flex-col justify-center items-center relative z-10">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, defineProps, onMounted, watch } from "vue";
import backgroundImagePeople from "@/assets/images/backgrounds/bg-agegate-md.jpg";
import backgroundImageGreen from "@/assets/images/backgrounds/bg-green-xl.jpg";
import { useGlobalStore } from "@/stores/global";

const globalStore = useGlobalStore();

const background = ref<string>(backgroundImageGreen);
const previousBackground = ref<string>(backgroundImageGreen);
const currentOpacity = ref(1);
const previousOpacity = ref(0);

const props = defineProps<{
  backgroundImage?: string;
}>();

onMounted(() => {
  setBackgroundImage();
});

watch(
  () => props.backgroundImage,
  () => {
    setBackgroundImage();
  }
);

const setBackgroundImage = () => {
  previousBackground.value = background.value;
  background.value =
    props.backgroundImage === "people" ? backgroundImagePeople : backgroundImageGreen;
  previousOpacity.value = 1;
  currentOpacity.value = 0;

  setTimeout(() => {
    previousOpacity.value = 0;
    currentOpacity.value = 1;
  }, 50);
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/main.sass";

.bg-cover {
  transition: opacity 0.8s ease-in-out;
  --webkit-transition: opacity 0.8s ease-in-out;
  height: 100vh;
}

.container {
  height: 100%;
}
</style>
